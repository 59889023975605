<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="440"
    content-class="v-dialog__form">
    <div class="dialog-content">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <span>Export types by custom view</span>
          </div>
          <span
            class="sourcery__icon-wrapper black"
            @click="cancel">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text
          class="items-wrapper">
          <div
            class="items">
            <div
              class="item-wrapper">
              <v-autocomplete
                v-model="selected"
                attach
                item-text="name"
                item-value="id"
                :allow-overflow="false"
                :menu-props="{
                  maxHeight: '220px',
                }"
                class="follow-products-autocomplete"
                no-data-text="No custom views available"
                :items="customViews"
                placeholder="Choose custom view for exporting">
                <template
                  #item="{ item, on }">
                  <v-list-item
                    v-on="on">
                    {{ item.name }}
                    <v-icon
                      small
                      class="ml-4">
                      mdi-tag-outline
                    </v-icon>
                    {{ item.filterTag }}
                  </v-list-item>
                </template>
                <template
                  #selection="{ item }">
                  {{ item.name }}
                  <v-icon
                    small
                    class="ml-4">
                    mdi-tag-outline
                  </v-icon>
                  {{ item.filterTag }}
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          class="pt-6 d-flex justify-end">
          <template>
            <v-btn
              outlined
              @click="cancel">
              Cancel
            </v-btn>
            <v-btn
              color="blue"
              class="white--text"
              :disabled="!selected"
              @click="exportSchedule">
              Export
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
export default {
  name: 'ProjectDetailsExportScheduleModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    customViews: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selected = null;
      }
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    exportSchedule() {
      this.$emit('export', {
        viewId: this.selected,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.items-wrapper {
  min-height: 335px;
  max-height: 50vh;
}
.v-card__actions {
  padding: 16px;
}
</style>
